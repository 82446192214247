@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Ms+Madi&display=swap");
.footer_image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}

footer.customFooter {
  position: relative;
  z-index: 55;
  background: #000;
  overflow: hidden; /* height: 100vh; */ /* min-height: 500px; */
}

.footer_image:after {
  content: "";
  opacity: 0.3;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer_title {
  position: absolute;
  z-index: 33;
  text-align: center;
  width: 100%;
  font-size: 177px;
  /* letter-spacing: -1.6px; */
  font-family: var(--heading-font);
  font-weight: 400;
  transform: rotate(3deg) translateY(-60%);
  top: 50%;
  font-family: "Better Fonts";
  pointer-events: none;
}

.footer_image_list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer_list-item {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
}
.footer_list-item.active {
  opacity: 1;
}
.footer_content_Wrp {
  height: 100vh;
  min-height: 500px;
  position: relative;
}

.footer_content_list {
  position: relative;
}

.fl_content_Box {
  text-align: center;
  padding: 50px 20px;
  position: relative;
}

.fl_content_inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100vh;
  min-height: 560px;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.colorBox {
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  transition: 0.5s all;
}

.fl_content_inner:hover .colorBox {
  height: 100%;
}
.fl_heading {
  display: inline-block;
  width: 100%;
}
.fl_heading h4 {
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0px;
  margin: 0 0 0;
  transition: 0.4s all;
}
.fl_text {
  font-size: 14px;
  margin-top: 10px;
  transition: 0.4s all;
  position: relative;
  opacity: 0;
  display: none;
}
.fl_content_inner:hover .fl_text {
  opacity: 0.8;
  margin-top: 0;
  transition-delay: 0.3s;
  display: block;
}

@media only screen and (max-width: 767px) {
  .fl_heading {
    position: absolute;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    left: 0;
    bottom: 30px;
    width: 10px;
  }

  .fl_content_Box {
    padding: 20px 0;
  }

  .colorBox {
    height: 13px;
  }

  .fl_content_inner .fl_text {
    display: none;
  }

  .fl_heading h4 {
    margin: 0;
    white-space: nowrap;
    font-size: 16px;
  }

  .footer_title {
    font-size: 100px;
    line-height: normal;
  }
}
