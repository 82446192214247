

.portfolio__section {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
}


  .portfolio__text {
    text-align: center;
    font-size: 150px;
    font-weight: 500;
    color: var(--white);
    text-transform: uppercase;
    /* padding: 120px 0 50px; */
    opacity: 1;
}


.portfolio__list-1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;
  position: relative;
  margin-bottom: 0;
}
.portfolio__item{
  position: relative;
}
.portfolio__item:nth-of-type(3n+2){
  /* opacity: 0.2; */
  top: 100px;
}

.portfolio__item a {
  position: relative;
  display: block;
  overflow: hidden;
}
.portfolio__info {
  width: calc(100% - 60px);
    padding: 30px;
    border-radius: 5px;
    background-color: #f1f1f17d;
    position: absolute;
    right: 0;
    bottom: 30px;
    z-index: 1;
    transform: translateX(0);
    transition: all 0.3s;
    opacity: 0;
    left: 0;
    backdrop-filter: blur(16px);
    color: #000;
    transition: 0.4s all linear;
}
.portfolio__info h3{
  margin-top: 0;
}
.portfolio__item a:hover .portfolio__info{
  transform: translateX(30px);
  opacity: 1;
}
.portfolio__tag > div{
  display: inline-block;
}
.portfolio__tag > div + div {
  margin-left: 10px;
}
.portfolio__item a img{
  border-radius: 15px;
  
}

/*  Responsive */

@media only screen and (max-width: 767px) {
  .portfolio__list-1 {
    grid-template: initial;
    gap: 10px;
  }
  .portfolio__item:nth-of-type(3n+2){
    top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .portfolio__list-1 {
    grid-template: initial;
    gap: 10px;
  }
  .portfolio__item:nth-of-type(3n+2){
    top: 0;
  }
}




