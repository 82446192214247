.work__achieve_box_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.25rem;
  width: 100%;
  padding-bottom: 10rem;
}

.work__achieve_box_item {
  will-change: transform, color, background;
  background: rgb(31 32 34 / 70%);
  position: relative;
  z-index: 0;
  display: inline-block;
  border-radius: 1.5rem;
  padding: 3.5rem;
}

.wa_box__number {
  letter-spacing: -5px;
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 2.5rem;
  width: 100%;
  overflow: hidden;
  font-size: 6rem;
  line-height: 0.8;
}

.wa_box__number_two {
  position: absolute;
  top: 0;
}

.wa_box__heading {
  font-size: 1.9rem;
  line-height: 1.6rem;
  transition: all 0.5s ease;
  width: 100%;
  align-self: flex-end;
  text-align: right;
  font-family: var(--heading-font);
}

.work__achieve_box_item:nth-child(2n) {
  margin-top: 10rem;
  margin-bottom: -10rem;
}

.work__achieve_box_item:after {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 1.5rem;
  content: "";
  transition: all 0.5s ease;
}
.work__achieve_box_item:hover {
  color: #121315;
}
.work__achieve_box_item:nth-child(1):hover {
  color: #e0eeee;
}

.work__achieve_box_item:nth-child(1):hover:after {
  background: rgba(221, 53, 0, 0.9);
}
.work__achieve_box_item:nth-child(2):hover:after {
  background: rgba(220, 229, 229, 0.9);
}
.work__achieve_box_item:nth-child(3):hover:after {
  background: rgba(176, 221, 202, 0.9);
}
.work__achieve_box_item:nth-child(4):hover:after {
  background: rgba(244, 227, 66, 0.9);
}
