.embla {
  --slide-spacing: 0;
  --slide-size: 100%;
  --slide-height: 100%;
  padding: 0;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}


.embla__parallax {
  height: 100%;
  overflow: hidden;
}
.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
}
.embla__parallax__img {
  max-width: none;
  width: calc(100% + (var(--slide-spacing) * 2));
  margin-left: calc(var(--slide-spacing) * -1);
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}


.serviceSliderButtonGroup {
  position: absolute;
  bottom: 40px;
  z-index: 22;
  right: 40px;
}