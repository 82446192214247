.header__area {
  position: fixed;
  width: 100px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9991;
  /* border-right: 1px solid rgba(240, 240, 240, 0.07); */
  mix-blend-mode: exclusion;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #fff;
}
.header__inner {
  padding: 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 9;
}
.logo-primary a {
  display: inline-block;
}

.logo-primary a img {
  height: 183px;
}

.header__support p {
  width: 215px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.3;
  color: var(--gray-2);
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  bottom: 34px;
}
.header__support p a {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: var(--white);
  text-decoration: none;
}

/* .header__support{
    height: 130px;
} */

/* New COde */

.hamburger-menu {
  display: none;
  z-index: 99;
  top: 30px;
  left: 100px;
  right: 0;
  position: fixed;
  height: calc(100% - 60px);
  width: calc(100% - 130px);
  border-radius: 20px;
  overflow: hidden;
}
.hamburger-menu .menu-secondary-background-color {
  background: #191919;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.hamburger-menu .menu-layer {
  position: relative;
  background: #313131;
  height: 100vh;
  overflow: hidden;
  /* padding: 40px; */
  background:linear-gradient(49deg, #0e322a 0%, #2e5e53 50%);
}
.hamburger-menu .menu-layer .menu-city-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: backgroundEffect;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}
.hamburger-menu .menu-layer .MenuWrap {
  position: relative;
  height: 100%;
}

.hamburger-menu .menu-layer .MenuWrap .menu-links {
  display: block;
  position: relative;
  padding: 30px 50px 80px;
  height: 80vh;
}
.hamburger-menu .menu-layer .MenuWrap .menu-links ul {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
  border-right:2px solid #305e53
}
.hamburger-menu .menu-layer .MenuWrap .menu-links ul li {
  list-style: none;
  font-size: 3.6rem;
  font-family: "Better Fonts";
  line-height: normal;
  position: relative;
  height: 84px;
}
.hamburger-menu .menu-layer .MenuWrap .menu-links ul li a {
  position: absolute;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.hamburger-menu .menu-layer .MenuWrap .menu-links ul li a:hover {
  color: var(--secondary);
}
.hamburger-menu .menu-layer .MenuWrap .menu-links .headerInfo {
  color: #fff;
}
.hamburger-menu .menu-layer .MenuWrap .menu-links .headerInfo h3 {
  font-size: 1.2rem;
  margin: 8px auto;
}
.hamburger-menu .menu-layer .MenuWrap .menu-links .headerInfo p {
  margin: 0 auto;
  font-size: 0.8rem;
}
.hamburger-menu .menu-layer .MenuWrap .locations {
  position: absolute;
  bottom: 20px;
  color: #fff;
  margin-top: 0;
  font-size: 0.8rem;
  font-weight: 600;
  z-index: 333;
  left: 60px;
  width: calc(60% - 20px);
  display: flex;
  justify-content: space-around;
  height: auto;
}
.hamburger-menu .menu-layer .MenuWrap .locations img {
  cursor: pointer;

  transition: 0.3s ease-in-out;
}
.hamburger-menu .menu-layer .MenuWrap .locations span:first-child {
  margin-left: 64px;
}
.hamburger-menu .menu-layer .MenuWrap .locations span:hover {
  color: #fff;
  background: #191919;
  padding: 8px 24px;
  border-radius: 4px;
}
@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.headerInfo {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 60px;
}
.menu-layer:before {
  content: "";
  background-repeat: repeat;
  background-position: top left;
  background-image: url("../../images/body-bg.png");
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}
.height100 {
  height: 100%;
}

.borderLeft {
  border-left: 2px solid black;
}

.HeaderContactInfo {
  padding: 40px;
  text-align: center;
  color: #ffc7c7;
  display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}



.social_Wrp {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.social_item {
  width: calc(100% / 4);
  position: relative;
}

.social_item a {
  display: block;
  height: 100%;
  background-position: center;
  position: relative;
  background-size: cover;
  opacity: 0.2;
  transition: 0.4s all linear;
}
.social_item a:hover{
  opacity: 1;
}
.social_item a .social_overlay {
  position: absolute;
  /* background-color: #b25537; */
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.3;
}
.socialText {
  position: absolute;
  top: 50%;
  writing-mode: vertical-rl;
  left: 50%;
  font-family: "Better Fonts";
  font-size: 60px;
  transform: translate(-50%, -50%) rotate(180deg);
  pointer-events: none;
}
.social_item a:hover .social_overlay{
  opacity: 0.9;
} 


.locationItem {
  width: 92px;
  margin-right: 20px;
  /* mix-blend-mode: overlay; */
  filter: grayscale(100%);
  display: inline-block;
  filter: brightness(0) invert(1);
  cursor: pointer;

  transition: 0.3s ease-in-out;
  opacity: 0.6;
}
.locationItem:hover{
  transform: translateY(-10px);
  opacity: 1;
}
.hamburger-menu .menu-layer .MenuWrap > div, .hamburger-menu .menu-layer .MenuWrap > div > div {
  height: 100%;
}




/* Humberger Button */

.btn12 {
  position: relative;
  width: 40px;
  height: 40px;
  transition-duration: 0.5s;
  background: transparent;
  border: 0;
  text-align: center;
  line-height: 40px;
  top: -60px;
}
.btn12 .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 30px;
  top: 30px;
  left: 0;
  background-color: #ffffff;
}
.btn12 .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  content: "";
  top: -9px;
  left: 0;
}
.btn12 .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  content: "";
  top: 9px;
  left: 0;
}
.btn12.open .icon {
  transition-duration: 0.5s;
  background: transparent;
}
.btn12.open .icon:before {
  transform: rotateZ(45deg) scaleX(1.25) translate(6px, 6px);
}
.btn12.open .icon:after {
  transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
}
.btn12:hover {
  cursor: pointer;
}

.h_info_item + .h_info_item {
  margin-top: 28px;
}
.h_info_icon svg {
  font-size: 41px;
  color: var(--secondary);
}

.h_info_item a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  opacity: 0.6;
}
.h_info_item a:hover{
  opacity: 1;
}
.h_info_item {
    position: relative;
    width: 100%;
    padding-left: 60px;

}
.h_info_item a label {
  display: block;
  font-size: 15px;
  color: #a4eaa8;
}
.h_info_icon {
  margin-right: 13px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}




/*  Responsive */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
       only screen and (min-resolution: 192dpi) {
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .hamburger-menu .menu-layer .MenuWrap .menu-links ul li{
    font-size: 2.8rem;
    height: 64px;
  }
  .locationItem{
    width: 67px;
  }
  .h_info_item a{
    font-size: 16px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .hamburger-menu .menu-layer .MenuWrap .menu-links ul li{
    font-size: 2.4rem;
    height: 58px;
  }
  .locationItem{
    width: 67px;
  }
  .h_info_item a{
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hamburger-menu .menu-layer .MenuWrap .menu-links ul li{
    font-size: 2.3rem;
    height: 54px;
  }
  .locationItem{
    width: 67px;
  }
  .h_info_item a{
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .header__area{
    width: 60px;
  }
  .logo-primary a img{
    height:110px ;
  }
  .header__support p{
    font-size: 12px;
    margin: 0;
  }
  .header__support p a{
    font-size: 17px;
  }
  .hamburger-menu{
    width: calc(100% - 60px);
    left: 60px;
  }
  .hamburger-menu .menu-layer .MenuWrap .menu-links ul li {
    font-size: 2rem;
    height: 60px;
}

.hamburger-menu .menu-layer .MenuWrap .menu-links {
    padding: 23px 25px;
    height: calc(100vh - 320px);
}

.hamburger-menu .menu-layer .MenuWrap .menu-links ul {
    border: 0;
}

.locationItem {
    width: 50px;
    margin-right: -9px;
}

.hamburger-menu .menu-layer .MenuWrap .locations {
    width: 100%;
    left: 0;
}

.hamburger-menu .menu-layer .MenuWrap > div, .hamburger-menu .menu-layer .MenuWrap > div > div {
    height: auto;
    display: block;
}

.socialText {
    font-size: 30px;
}

.social_Wrp {
}

.HeaderContactInfo {
    height: 20px;
    padding: 0;
    display: none;
}

.social_item a {
    margin-top: 0;
    height: 100px;
}
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  
}
@media only screen and (max-width: 480px) {
  
}

