.panels {
  display: flex;
  margin: 0 -20px;
}

.panels__item {
  width: calc(100% / 5);
  padding: 0 20px;
}


.howweworkSection {
  /* height: 100vh; */
}



@keyframes fadeInUp {
  0% {
    visibility: hidden;
    filter: blur(6px);
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    visibility: inherit;
    filter: none;
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutDown {
  100% {
    visibility: hidden;
    filter: blur(6px);
    opacity: 0;
    transform: translateY(10px);
  }
}

.panels__item-content {
  padding-top: 30px;
  animation: fadeOutDown 1s both;
}

.panels__item-content .panels__item.is-active {
  animation: fadeInUp 1s both;
}






.stepInner {
  position: relative;
  text-align: center;
  padding: 0 31px 20px;
}

.stepInner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  top: 58px;
  /* background-color: #4b4b4b; */
}

.workflow__step {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: #ffffff;
  text-transform: capitalize;
  padding-bottom: 50px;
  margin-top: 0;
  font-family: var(--primary-font);
}

.workflow__number {
  font-size: 68px;
  font-weight: 500;
  color: #655f5f;
  margin: 0;
  line-height: 1px;
  font-family: var(--heading-font);
  transition: 0.4s all
}

.workflow__title {
  font-weight: 500;
  font-size: 23px;
  line-height: 1.2;
  color: #fff;
  margin: 60px 0 0;
  padding-bottom: 9px;
  font-family: var(--heading-font);
  transition: 0.4s all;
}

.stepInner p {
  max-width: 230px;
  margin: 0 auto;
  color: #fff;
  opacity: 0.5;
}

@keyframes wcBubble {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.5;
  }

  100% {
    scale: 1;
  }
}

.stepInner::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  left: calc(50% - 9px);
  top: 50px;
  background-color:var(--primary);
  /* border: 3px solid var(--white); */
  border-radius: 100%;
  -webkit-animation: wcBubble 1.5s 1s infinite;
  animation: wcBubble 1.5s 1s infinite;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.hiw_img {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 0;
  right: 0;
  top: -15px;
  z-index: 3;
  border-radius: 100%;
  transition: 0.4s all;
  transform: scale(0.13);
  margin: auto;
}

.stepInner:hover .hiw_img {
  transform: scale(1);
  box-shadow:0px 10px 20px 2px #8b9c8c73;
}
.stepInner:hover .workflow__number{
  transform: translateY(-23px);
}

/* .stepInner:hover .workflow__step{
  transform: scale(0.2);
} */



/*  Responsive */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .stepInner{
    padding: 0 10px 20px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .stepInner{
    padding: 0 10px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .stepInner{
    padding: 0 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
 
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
}
@media only screen and (max-width: 480px) {
  
  
}
