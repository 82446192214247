
.cl_box_inner {
    width: 220px;
    height: 220px;
    text-align: center;
    border: 1px solid rgb(255 255 255 / 23%);
    border-radius: 50%;
    transition: all .4s;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 30px;
    position: relative;
    overflow: hidden;
}

.cl_item {
    margin: 0 -20px;
}

.cl_box_inner img {
    -webkit-filter: invert(62%)  grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: invert(62%)  grayscale(100%);
width: 170px;
    transition: 0.4s all linear;
    position: relative;
    z-index: 4;
    max-width: 100%;
}
.clientLogoWrp .rfm-initial-child-container{
    padding: 180px 0 200px;
   
}
.clientLogoWrp{
    overflow: hidden;
    position: relative;
    z-index: 55;
}
.cl_box_wrp .rfm-marquee .rfm-child:nth-child(odd){
    margin-top: -180px;
}
.cl_box_wrp .rfm-marquee .rfm-child:nth-child(even){
    margin-bottom: -180px;
}

.cl_box_inner:hover{
    border-color:#fff ;
}
.cl_box_inner:hover img{
    -webkit-filter: invert(0%)  grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: invert(0%)  grayscale(0%);
    opacity: 1;
}


.cl_box_inner:after {
    background: #ffffff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    border-radius: 100%;
    transform: scale(0.8);
    opacity: 0;
    transition: 0.4s all linear;
}

.cl_box_inner:hover:after {
    opacity: 1;
    transform: scale(1);
}


@media only screen and (max-width: 767px) {

    .cl_box_inner {
        width: 160px;
        height: 160px;
    }
}