
.WeeklySec {
    /* font-family: "Alfa Slab One", serif; */
    font-family: var(--heading-font);
    font-optical-sizing: auto;
   
  }


  .WeeklySec .name, .WeeklySec .title{
  /* font-size: 7rem; */
  /* font-family: "Alfa Slab One", serif; */
}

.wd_title {
    position: absolute;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    top: -15%;
    left: 0;
    opacity: 0.03;
    width: 100%;
    text-align: center;
    justify-content: center;
    pointer-events: none;
}
.wd_title h2 {
  margin: 0;
  white-space: nowrap;
  width: 100%;
  font-family: var(--heading-font);
}
/* .weeklyBox:hover {
  flex: 1.5 1;
}

.weeklyBox:hover ~ .box {
  flex: 1 1;
} */


.weeklyOver {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}