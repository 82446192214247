
.scroll-to-top img {
    width: 70px;
    height: 70px;
    filter: brightness(0) invert(1);

}

.scroll-to-top{
    position: relative;
    width: 70px;
    height: 70px;
    cursor: pointer;
    z-index: 444;
}
.v-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}