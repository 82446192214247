.pageFeaturedImageSec {
  width: 100%;
  position: relative;
  height: 100vh;
  min-height: 560px;
  background-color: #000;
}

.pageFeaturedImageSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.servicewrp {
  overflow: hidden;
  position: relative;
  background: #000;
}

.page_sidebox {
  position: fixed;
  top: 0;
  z-index: 54;
  height: 100vh;
  left: 100px;
  display: flex;
}

.pageFeaturedImageSec:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.5;
  pointer-events: none;
}

.servicePageWrp {
  overflow: hidden;
}

.page-active h2 {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  letter-spacing: 5px;
  font-size: 19px;
  font-weight: 400;
  text-transform: uppercase;
}

.box-first-left {
  border-left: 1px solid #ffffff14;
  border-right: 1px solid #ffffff14;
  width: 110px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.box-second-left {
  width: 140px;
  border-right: 1px solid #ffffff14;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.page_title {
  font-size: 100px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  pointer-events: none;
  opacity: 0.25;
  will-change: transform;
  white-space: nowrap;
  line-height: 1.3;
}
.letter-stroke {
  color: transparent;
  -webkit-text-stroke: 1.2px white;
  padding-bottom: 30px;
}

.page_contentbox {
  position: relative;
  padding-left: 390px;
  padding-right: 100px;
}

.pageFeature_content {
  position: absolute;
  z-index: 70;
  width: 570px;
  left: 390px;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 500px);
}

.description {
  font-size: 18px;
  opacity: 0.8;
  position: relative;
  margin: 30px 0 0;
}

.border-before {
  border-left: 3px solid #fff;
  padding-left: 24px;
}




.service_CTA_sec {
  padding: 40px;
  background: #000;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  border: 2px solid #494747;
  overflow: hidden;
}
.cta_effect_1 {
  height: 511px;
  width: 511px;
  border-radius: 50%;
  background: rgba(244, 190, 27, 0.2);
  filter: blur(150px);
  display: inline-block;
  position: absolute;
  left: -50px;
  top: -36%;
}

.cta_effect_2 {
  height: 462px;
  width: 462px;
  border-radius: 50%;
  background: rgba(132, 153, 78, 0.5);
  filter: blur(190px);
  display: inline-block;
  position: absolute;
  right: -20%;
  bottom: -30%;
}

.cta_effect_3 {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: linear-gradient(179.29deg, rgb(255 255 255 / 12%) 0.58%, rgba(31, 45, 24, 0) 40.98%);
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -37%;
}