.ib_circleBox {
    width: 230px;
    height: 230px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    padding: 27px;
    position: relative;
    flex-direction: row;
}

.ib_circle {
    /* border: 4px solid red; */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s all linear;
    overflow: hidden;
    border-radius: 100%;
    z-index: 2;
    padding: 8px;

   
}
.ib_circle span{
    background-color: #000;
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 4px;
    top: 4px;
    z-index: 3;
    border-radius: 100%;
    transition: 0.6s all linear;
}
.ib_circle:before {
content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
    background-size: 50% 100%
    
}
.iconBox:hover .ib_circle {
    transform: rotate(65deg) scale(0.85);
    border-radius: 13%;
}
.iconBox:hover .ib_circle span{
    border-radius: 13%;
}
.iconBox:hover .ib_circle:before {
    animation: rainbow-btn .75s linear infinite
}
.iconBox.angle1 .ib_circle {
    transform: rotate(45deg) scale(0.85);
    border-radius: 13%;
}
.iconBox.angle1 .ib_circle span{
    border-radius: 13%;
}
.iconBox.angle1:hover .ib_circle{
    transform: rotate(0deg) scale(1);
    border-radius: 100%;
}
.iconBox.angle1:hover .ib_circle span{
    border-radius: 100%;
}

