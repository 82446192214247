

.button {
	color: #fff;
    border: 1px solid #fff;
    font-family: var(--heading-font);
    pointer-events: auto;
    cursor: pointer;
    background: #e7e7e700;
    padding: 20px 40px;
    margin: 0;
    position: relative;
    display: inline-block;
    border-radius: 70px;
    font-size: 21px;
    text-transform: none;
    /* letter-spacing: 1.5px; */
    text-decoration: none;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button--atlas > span {
    display: flex;
    align-items: center;
}

.button--atlas:hover > span {
	opacity: 0;
}
.button > span svg {
    margin-left: 11px;
    font-size: 21px;
    width: auto;
}
.button .marquee {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	pointer-events: none;
    height: 100%;
}

.button .marquee__inner {
	width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative;
    --offset: 1rem;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 2s linear infinite;
    animation-play-state: paused;
    opacity: 0;
    height: 100%;
    align-items: center;
}

.button--atlas:hover .marquee__inner {
	animation-play-state: running;
	opacity: 1;
	transition-duration: 0.4s;
}
.button--atlas:hover {
    animation: btnBgChange 5s linear infinite;
    color: #000;
}

.button .marquee span {
	text-align: center;
	white-space: nowrap;
	font-style: italic;
	padding:20px 10px;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}
@keyframes btnBgChange {
	0% {
		background: #F9D8D6;
        border-color:#F9D8D6;
	}

	25% {
		background: #F9EBDF;
        border-color:#F9EBDF;
	}
	50% {
		background: #EFF9DA;
        border-color:#EFF9DA;
	}
	75% {
		background: #CDF5F6;
        border-color:#CDF5F6;
	}
	100% {
		background: #CBE4F9;
        border-color:#CBE4F9;
	}
}