.roll_wrp {
    padding: 20px 0;
    position: relative;
}

.roll_wrp .roll_wrp_item {
    font-size: 22px;
    text-align: center;
    padding: 0 100px;
}

/* Brand Info */
.bi_inner img {
    max-width: 140px;
}

.bi_inner {
    padding: 20px 30px;
    text-align: left;
    padding-left: 60px;
}

.bi_inner h3 {
    font-size: 29px;
    color: #fff;
    margin: 20px 0 29px;
}

.bi_inner ul {
    list-style: none;
    opacity: 0.8;
    margin-bottom: 0;
    padding: 0;
}

.bi_inner p {
    opacity: 0.8;
}


/*  Responsive */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .roll_wrp .roll_wrp_item{
        padding: 0 90px;
    }
    .bi_inner{
        padding-left: 10px;
    }
    .about__content p{
        font-size: 18px;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
    .roll_wrp .roll_wrp_item{
        padding: 0 80px;
    }
    .bi_inner{
        padding-left: 10px;
    }
    .about__content p{
        font-size: 16px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .roll_wrp .roll_wrp_item{
        padding: 0 70px;
    }
    .bi_inner{
        padding-left: 10px;
    }
}
@media only screen and (max-width: 767px) {
    .roll_wrp .roll_wrp_item{
        padding: 0 60px;
    }
    .bi_inner{
        padding: 20px 0;
    }
    .bi_inner img{
        max-width: 100px;
    }
    .about__content p{
        font-size: 16px;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
}
@media only screen and (max-width: 480px) {
    .roll_wrp .roll_wrp_item{
        padding: 0 40px;
        font-size: 18px;
    }
}