@import url(./fonts/stylesheet.css);
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

:root {
  --primary: #2E5E53;
  --secondary: #8B9C8C;
  --third: #262a53;
  --primary-font: "Space Grotesk", sans-serif;
  --heading-font: "Recoleta";
  --black: #000;
  --white: #fff;
  --gray: #c2c2c2;
  --pink: #faede9;
  --bg-line: #1e1e1e;
  --d-gray: #6a6a6a;
  --d-black: #1a1a1a;
  --bg-gradient: linear-gradient(45deg, #607d8b 0%, #546e7a 50%, #455a64 100%);
  --cursor-color: #fff;
}

* {
  box-sizing: border-box !important;
}
body {
  background-color: #000;
  font-family: var(--primary-font);
  color: #ffffffde;
  font-optical-sizing: auto;
  line-height: 1.5;
  font-size: 17px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-weight: normal;
  line-height: 1.1;
}
.body-bg {
  position: fixed;
  z-index: 9;
  pointer-events: none;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
  background-position: top left;
  background-image: url(./images/body-bg.png);
}
.p-relative {
  position: relative;
}

.css-1oqqzyl-MuiContainer-root {
  padding-left: 110px !important;
  max-width: 100% !important;
  padding-right: 110px !important;
  position: relative;
  z-index: 4;
}
.sectionWrp {
  padding: 100px 0;
  /* position: relative; */
  z-index: 33;
}
.img-fluid {
  width: auto;
  max-width: 100%;
  height: auto;
}

.blur-bg-theme {
  position: absolute;
  right: 0;
  top: 0;
  height: 300px;
  width: 300px;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--bg-gradient);
  filter: blur(100px);
}

.trx_addons_image_effects_holder {
  position: relative;
}

.gradient_text {
  background-image:-webkit-linear-gradient(0deg, var(--secondary), var(--primary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.startBgImgWrp {
  position: absolute;
  width: 100%;
  top: -100px;
  left: 0;
  height: 100px;
}

@keyframes rainbow-btn {
  to {
    transform: translateX(-50%);
  }
}
.mainContentWrp {
  position: relative;
  min-height: 100vh;
  /* background-color: #2c2c2c; */
  /* position: relative;
  z-index: 997; */
}

/* section{
  background-color: #2c2c2c; 
   position: relative;
  z-index: 998;
} */

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--black);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.2);
}

.blur-bg-img {
  background-image: url("./images/bg_p_3.png");
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background-size: cover;
}
.content_404 h1 {
  font-size: 39px;
  text-align: center;
}

.content_404 h1 span {
  font-size: 1000%;
  display: block;
  color: var(--secondary);
  font-family: 'Better Fonts';
  line-height: 0.5;
}
section.page_404 {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 600px;
}
/*  Responsive */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  body{
    font-size: 16px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  body{
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body{
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .css-1oqqzyl-MuiContainer-root {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  body{
    font-size: 15px;
  }
  .offerModalInner {
    display: flex
;
    flex-wrap: wrap;
}
.modalBgWrp{
  width: calc(100% - 80px) !important;
  left: 60px !important;
  right: 20px;
  margin: unset !important;
}
.offerModalInner h2{
  font-size: 35px !important;
}
.offerModalTextWrp{
  padding-right: 0 !important;
}
.offerModalInner h2 span{
  display: inline-block !important;
  font-size: 60px !important;
}
.offerClaimSec canvas{
  width: 200px !important;
  margin-top: 20px;
}
.modalBgWrp{
  padding: 0 !important;
}
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
}
@media only screen and (max-width: 480px) {
  .css-1oqqzyl-MuiContainer-root {
    padding-left: 65px !important;
    padding-right: 15px !important;
  }
  body{
    font-size: 15px;
  }
  
}
